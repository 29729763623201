import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

/** Сервис аутентифкации */
@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private http: HttpClient, private router: Router) { }

  /** Вход в систему */
  public logIn(authParam: AuthParam): Observable<any> {
    const url = environment.API + 'api/auth';
    return this.http.post(url, authParam);
  }

  /** Установка данных пользователя в локальное хранилище */
  public setAuthUserData(data: any): void {
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('user_roles', JSON.stringify(data.roles));
    localStorage.setItem('ya_maps_key', data.yaMapsKey);
  }

  /** Выход из системы */
  public logOut(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_roles');
    localStorage.removeItem('ya_maps_key');
  }

  /** Выход и переход на страницу авторизации */
  public logOutAndRoute(): void {
    this.logOut();
    this.router.navigate(['/login']);
  }

  /** Возвращает имя пользователя*/
  public userName(): Observable<string> {
    const url = environment.API + 'api/currentuser/name';
    return this.http.get(url, { responseType: 'text' });
  }

  /** Истина если пользователь авторизован */
  get isLoggedIn(): boolean {
    const token = localStorage.getItem('access_token');
    // нужно проверить не только что токен есть но и то что он живой
    return token !== undefined && token !== null;
  }
}

/** Данные аутентификации*/
export class AuthParam {
  public login?: string;
  public password?: string;

  /** Валидность */
  public IsValid(): boolean {
    if (!this.login
      || this.login.trim().length === 0
      || !this.password
      || this.password.length === 0) {

      return false;
    }

    return true;
  }
}
