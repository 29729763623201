import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ErrorHandlingInterceptor, TokenHttpInterceptor } from './shared/interceptors';
import { AppEnviromentService } from './shared/services/app-enviroment.service';
import { AuthService } from './shared/services/auth.service';
import { EventLogsService } from './shared/services/event-logs.service';
import { GlobalMsgService } from './shared/services/global-msgs.service';
import { CheckRoleService } from './shared/services/check-role.service';

import localeRU from '@angular/common/locales/ru';
import { SharedComponentsModule } from './shared/shared-components.module';
import { PrimeContainerModule } from './shared/prime-container.module';
import { LoginFormComponent } from './pages/login-form/login-form.component';
import { MainFormComponent } from './pages/main-form/main-form.component';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AboutDlgComponent } from './pages/main-form/about-dlg/about-dlg.component';
import { DateInterceptor } from './shared/interceptors/date.interceptor';
import { MapsLoaderService } from './shared/services/map-loader.service';
import { TooltipModule } from 'primeng/tooltip';

registerLocaleData(localeRU, 'ru');
@NgModule({
  declarations: [
    AppComponent,
    LoginFormComponent,
    MainFormComponent,
    AboutDlgComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    PrimeContainerModule,
    SharedComponentsModule,
    AppRoutingModule,
    TooltipModule
  ],
  providers: [
    AuthService,
    { provide: LOCALE_ID, useValue: 'ru' },

    { provide: HTTP_INTERCEPTORS, useClass: TokenHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true},

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true
    },

    EventLogsService,
    AppEnviromentService,
    GlobalMsgService,
    MessageService,
    ConfirmationService,
    DialogService,
    CheckRoleService,
    MapsLoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
