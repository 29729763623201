import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

/** Сервис инофрмаии о окружении */
@Injectable({ providedIn: 'root' })
export class AppEnviromentService {
  constructor(private http: HttpClient) { }
  public get title() {
    return 'Система планирования и контроля обходов точек учета электрической энергии';
  }
  public get shortTitle() {
    return 'ЧЭСК';
  }

  /** Возвращает имя пользователя */
  public getEnviromentInfo(): Observable<IAppEnviroment> {
    return this.http.get<IAppEnviroment>(environment.API + 'api/app/enviroment');
  }

  /** Возвращает API для яндекс карт */
  public getYaMapsKey(): string {
    return localStorage.getItem('ya_maps_key');
  }
}

/**Информация о приложении */
export interface IAppEnviroment {
  /**Версия приложения */
  version: string;

  /**Строка подключения к бд */
  connectionDb: string;
}

