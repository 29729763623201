<p-toolbar>
    <div class="p-toolbar-group-left">
    </div>
    <div class="p-toolbar-group-right">
        <div class="wrapper">
            <p-button type="button" label="{{cancelTitle}}" icon="fas fa-ban" styleClass="app-btn-outlined" (onClick)="cancelClick()">
            </p-button>
            <p-button type="button" label="{{applyTitle}}" icon="pi pi-save" styleClass="app-btn-outlined" active [disabled]="isDisabled" (onClick)="applyClick()">
            </p-button>
        </div>
    </div>
</p-toolbar>
