import { MessageService } from 'primeng/api';
import { Injectable } from '@angular/core';

/** Сервис уведомлений. Компонент определен в app.component */
@Injectable({ providedIn: 'root' })
export class GlobalMsgService {
  constructor(private messageService: MessageService) { }

  /** Показать сообщение о ошибке */
  public error(msg: string): void {
    console.log(msg);
    this.messageService.add({ severity: 'error', summary: 'Сообщение об ошибке: ', detail: msg, key: 'global', life: 5000 });
  }

  /** Показать предупреждение */
  public warning(msg: string): void {
    this.messageService.add({ severity: 'warn', summary: 'Предупреждение: ', detail: msg, key: 'global', life: 5000 });
  }

  /** Показать информационное сообщение */
  public info(msg: string): void {
    this.messageService.add({ severity: 'info', summary: 'Информационное сообщение: ', detail: msg, key: 'global', life: 2000 });
  }

  /** Сообщение о удаче */
  public success(msg: string): void {
    this.messageService.add({ severity: 'success', summary: '', detail: msg, key: 'global', life: 2000 });
  }

  /** Очистить все сообщения */
  public clear(): void {
    this.messageService.clear();
  }
}
