import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-svg-icons',
  templateUrl: './svg-icons.component.html',
  styleUrls: ['./svg-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconsComponent {
  @Input() type: string; // тип иконки
  @Input() color = '#787888';
  constructor() { }
}
