import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

/* Описывает методы для работы с журналом событий */
@Injectable({ providedIn: 'root' })
export class EventLogsService {
  constructor(private http: HttpClient) { }

  /** Добавить событие в журнал событий */
  public addEvent(moduleName: string, subject: string, description: string): void {
    this.sendEvent(moduleName, subject, description).subscribe();
  }

  /** Получает журнала событий по датам */
  public getLogByDates(beginDate: Date, endDate: Date): Observable<IEventLog[]> {

    const beginDateStr = moment(beginDate).format('YYYY-MM-DDTHH:mm:ss');
    const endDateStr = moment(endDate).format('YYYY-MM-DDTHH:mm:ss');
    const url = environment.API + 'api/eventlogs/'
      + '?beginDate=' + beginDateStr
      + '&endDate=' + endDateStr;
    return this.http.get<IEventLog[]>(url);
  }

  /** Очистка журнал  событий */
  public clearLogs(): Observable<any> {
    return this.http.delete(environment.API + 'api/eventlogs');
  }

  /** Отправить событие на сервер */
  private sendEvent(moduleName: string, subject: string, description: string): Observable<any> {
    const url = environment.API + 'api/clientlog';
    const body = { module: moduleName, subject, description };
    return this.http.post(url, body);
  }
}

export interface IEventLog {
  id: string;
  createDt: Date;
  userName: string;
  module: string | null;
  description: string | null;
}
