import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

/** Описывает компонент для отображаения сообщения  */
@Component({
  selector: 'app-alert-dlg',
  templateUrl: './alert-dlg.component.html',
  styleUrls: ['./alert-dlg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertDlgComponent {
  public message: string = '';

  /** ctor */
  constructor(private ref: DynamicDialogRef,
    private config: DynamicDialogConfig) {
    this.message = this.config.data.message;
  }

  public okClick(): void {
    this.ref.close();
  }
}
