<ng-container *ngIf="isAutorized(); else notAuthorized">
  <app-main-form></app-main-form>
</ng-container>

<ng-template #notAuthorized>
  <router-outlet></router-outlet>
</ng-template>

<p-confirmDialog header="Подтверждение" icon="pi pi-question-circle" acceptButtonStyleClass="app-btn-gray"
  rejectButtonStyleClass="app-btn-outlined">
</p-confirmDialog>

<p-toast position="top-right" key="global"></p-toast>