import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HttpErrorResponse } from '@angular/common/http';
import { MenuGroupConfig } from 'src/app/menu-config';
import { AppEnviromentService } from 'src/app/shared/services/app-enviroment.service';
import { AuthParam, AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  public authParam = new AuthParam();
  public isAuthWait = false; // индикатор подключения
  public pwdVisible = false; // признак показать/скрыть пароль
  public appTitle: string;
  private saveLoginKey = 'lk_login'; // ключ сохранения логина

  /** ctor */
  constructor(private router: Router,
    private authService: AuthService,
    private alert: AlertService,
    private enviromentService: AppEnviromentService) {
    if (authService.isLoggedIn) {
      authService.logOut();
    }
    this.appTitle = enviromentService.title;
  }

  /** ngOnInit */
  ngOnInit(): void {
    this.authParam.password = '';
    this.authParam.login = '';
    const savedLogin = localStorage.getItem(this.saveLoginKey);
    if (savedLogin) {
      this.authParam.login = savedLogin;
    }
  }

  /** Аунтефикация */
  public loginClick(): void {
    if (!this.authParam.IsValid()) {
      this.alert.show('Ошибка аутентификации', 'Введите логин пароль');
      return;
    }

    this.isAuthWait = true;
    this.authService.logIn(this.authParam).subscribe(
      data => {
        if (this.checkWebUser(data)) {
          this.setToken(data);
        }
      },
      err => this.showError(err)
    );
  }

  /** Срабатывает при нажатии на любую кнопку клавиатуры */
  public pwdKeyPress(e: any): void {
    if (e && e.charCode === 13) {
      this.loginClick();
    }
  }

  /** Показать/скрыть пароль */
  public tooglePswd(): void {
    this.pwdVisible = !this.pwdVisible;
  }

  /**Проверка на пользователя веб-части */
  private checkWebUser(data: any): boolean {
    const mobileController = data.roles.filter(x => x == 'Mobile_Controller')
    if(mobileController.length > 0) {
      this.isAuthWait = false;
      this.showWebUserError();
      this.authService.logOut();
      return false;
    }
    return true;
  }

  /** Установка токена безопастности */
  private setToken(data: any): void {
    const login = this.authParam.login;
    localStorage.setItem(this.saveLoginKey, login ? login : '');
    this.authService.setAuthUserData(data);
    this.isAuthWait = false;

    const menuGroups = new MenuGroupConfig().getUserMenuItems();
    if (menuGroups.length === 0) {
      this.router.navigate(['/']);
      return;
    }
    let routePath: string | undefined;
    if (menuGroups[0].childs !== undefined) {
      routePath = menuGroups[0].childs[0]?.path;
    }
    else {
      routePath = menuGroups[0].path;
    }
    this.router.navigate([routePath]);
  }

  /** Отображение ошибки */
  private showError(error: any): void {
    this.isAuthWait = false;
    if (error instanceof HttpErrorResponse && error.status === 0) {
      return;
    }

    this.alert.show('Ошибка аутентификации', error.error);
  }

  /** Отображение ошибки для пользователя с привелегией Мобильный обходчик*/
  private showWebUserError(): void {
    this.alert.show('Ошибка аутентификации', 'Доступ запрещен');
  }
}
