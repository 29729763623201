import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

/** Описывает кнопки Отмены и Сохранения */
@Component({
  selector: 'app-cancel-save-btn',
  templateUrl: './cancel-save-btn.component.html',
  styleUrls: ['./cancel-save-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelSaveBtnComponent {
  @Input() cancelTitle = "Отменить";
  @Input() applyTitle = "Сохранить";
  @Input() isDisabled = false;

  @Output() cancelChange = new EventEmitter()
  @Output() applyChange = new EventEmitter()

  /** Отмена */
  public cancelClick(): void {
    this.cancelChange.emit('cancel');
  }

  /** Применить */
  public applyClick(): void {
    this.applyChange.emit('apply');
  }
}
