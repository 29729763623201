import { Injectable } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';

@Injectable({ providedIn: 'root' })
export class FileService {

  /** Сохранение файла по blob ответу */
  public saveAs(r: Response): void {
    const fileName = this.getFileName(r);
    if (r.body instanceof Blob) {
      importedSaveAs(r.body, fileName);
    } else {
      console.log('Response body NOT BLOB ');
    }
  }

  /** Сохраняет блоб */
  public saveBlob(b: Blob, name: string): void {
    importedSaveAs(b, name);
  }


  /** Возвращает наименование файла из  Content-Disposition */
  private getFileName(r: Response): any {
    const contentDisposition = r.headers.get('Content-Disposition');
    let fileName = 'unknownfile';
    if (contentDisposition) {
      const decodeContent = decodeURIComponent(contentDisposition);
      const filenames = decodeContent.split(';').filter(x => x.includes('filename'));
      if (filenames.length > 0) {
        const utfTemplate = 'filename*=UTF-8\'\'';
        const utfFileNames = filenames.filter(x => x.includes(utfTemplate));
        if (utfFileNames && utfFileNames.length > 0) {
          fileName = utfFileNames[0].replace(utfTemplate, '').trim();
        } else {
          fileName = filenames[0].replace('filename=', '').trim();
        }
      }
    }
    return fileName;
  }
}
