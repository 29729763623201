import { UserRoles } from './shared/models/userroles';

export interface MenuItem {
  id: string,
  icon: string,
  caption: string,
  path?: string,
  roles?: string[],
  childs: SubmenuItem[],
}

export interface SubmenuItem {
  caption: string,
  path: string,
  roles: string[],
}

/** Конфигурация меню */
export class MenuGroupConfig {

  // все элементы меню
  private allMenuItems = [
    {
      id: 'app-routes',
      caption: 'Журнал маршрутов',
      icon: 'map',
      childs: [
        {
          caption: 'Журнал маршрутов',
          path: '/routes/journal',
          roles: ['Admin', 'Route_Viewer']
        }
      ],
    },
    {
      id: 'report',
      caption: 'Отчеты ФЛ',
      icon: 'report',
      childs: [
        {
          caption: 'Акт об ограничении/приостановлении представления',
          path: '/report/limitation',
          roles: ['Admin']
        },
        {
          caption: 'Акт о возобновлении подачи электроэнергии',
          path: '/report/resumption',
          roles: ['Admin']
        },
        {
          caption: 'Анкета потребителя',
          path: '/report/consumer',
          roles: ['Admin']
        },
        {
          caption: 'Акт проверки узла учета электрической энергии',
          path: '/report/checking',
          roles: ['Admin']
        },
        {
          caption: 'Акт об отказе в допуске к прибору учета',
          path: '/report/accessdenial',
          roles: ['Admin']
        },
        {
          caption: 'Акт о выявлении несанкционированного подключения',
          path: '/report/unconnection',
          roles: ['Admin']
        },
        {
          caption: 'Акт о несанкционированном вмешательстве в работу прибора учета',
          path: '/report/unintervention',
          roles: ['Admin']
        },
        {
          caption: 'Акт ввода в эксплуатацию средств учета ',
          path: '/report/commissioning',
          roles: ['Admin']
        },
        {
          caption: 'Ведомость снятия показаний',
          path: '/report/deviceReadings',
          roles: ['Admin']
        }
      ],
    },
    {
      id: 'report-ee',
      caption: 'Отчеты ЮЛ',
      icon: 'report',
      childs: [
        {
          caption: 'Акт допуска в эксплуатацию прибора учета электрической энергии (ЮЛ)',
          path: '/report-ee/deviceAdmission',
          roles: ['Admin']
        },
        {
          caption: 'Акт контрольного снятия показаний приборов учета электрической энергии (ЮЛ)',
          path: '/report-ee/controlMeterReading',
          roles: ['Admin']
        },
        {
          caption: 'Акт контрольной проверки средств учета Потребителей - юридических лиц - 0, 4 кВ (ЮЛ)',
          path: '/report-ee/controlCheckAccount',
          roles: ['Admin']
        },
        {
          caption: 'Акт на замену средств учета электроэнергии Потребителей - юридических лиц - 0, 4 кВ (ЮЛ)',
          path: '/report-ee/deviceChangeJuridical',
          roles: ['Admin']
        },
        {
          caption: 'Акт о безучетном потреблении электроэнергии (ЮЛ)',
          path: '/report-ee/unaccountedConsumption',
          roles: ['Admin']
        },
        {
          caption: 'Акт о необеспечении доступа (ЮЛ)',
          path: '/report-ee/nonProvisionAccess',
          roles: ['Admin']
        } ,
        {
          caption: 'Акт проверки введенного ограничения режима потребления электрической энергии (ЮЛ)',
          path: '/report-ee/consumptionLimitCheck',
          roles: ['Admin']
        }         
      ]
    },    
    {
      id: 'report-internal',
      caption: 'Внутренние отчёты',
      icon: 'report',
      childs: [
        /*
        {
          caption: 'Результат по маршрутному листу',
          path: '/report-internal/tasks-for-period-report',
          roles: ['Admin']
        },
        */
        {
          caption: 'Задания за период',
          path: '/report-internal/tasks-for-period-report',
          roles: ['Admin']
        },
        {
          caption: 'Маршрутный лист',
          path: '/report-internal/route-list-report',
          roles: ['Admin']
        },        
        {
          caption: 'Статистика выполнения маршрутов',
          path: '/report-internal/statistics-route-execution',
          roles: ['Admin']
        }
      ]
    },
    {
      id: 'app-dictionary',
      caption: 'Справочники',
      icon: 'refbooks',
      roles: ['Admin'],
      childs: [
        {
          caption: 'Причины не снятия показаний',
          path: '/dictionary/cancel-reason',
          roles: ['Admin']
        },
        {
          caption: 'Места установки ПУ',
          path: '/dictionary/installation-place',
          roles: ['Admin']
        },
        {
          caption: 'Статусы ПУ',
          path: '/dictionary/meter-status',
          roles: ['Admin']
        },
        {
          caption: 'Абоненты',
          path: '/dictionary/owner',
          roles: ['Admin']
        }
      ]
    },
    {
      id: 'app-settings',
      caption: 'Пользователи',
      icon: 'user2',
      roles: ['Admin'],
      childs: [
        {
          caption: 'Пользователи',
          path: '/security/users',
          roles: ['Admin']
        },
        {
          caption: 'Роли',
          path: '/security/roles',
          roles: ['Admin']
        }
      ],
    },
    {
      id: 'event-log-journal',
      caption: 'Журнал событий',
      icon: 'journal',
      roles: ['Admin'],
      childs: [
        {
          caption: 'Стационарный АРМ',
          path: '/security/event-logs',
          roles: ['Admin']
        },
        {
          caption: 'Мобильное приложение',
          path: '/security/event-logs-mobile',
          roles: ['Admin']
        }
      ],
    }
  ];

  /** ctor */
  constructor() { }

  /** Возвращает элементы меню доступные авторизованному пользователю */
  public getUserMenuItems(): MenuItem[] {
    const userRoles = new UserRoles().get();
    const userGroups = [];

    // tslint:disable: prefer-for-of
    for (let i = 0; i < this.allMenuItems.length; i++) {
      const group = this.allMenuItems[i];
      const groupMenuItems = group.childs;

      const userChilds = [];
      if (groupMenuItems !== undefined) {
        for (let j = 0; j < groupMenuItems.length; j++) {
          const menuItem = groupMenuItems[j];
          if (menuItem.roles.length === 0) {
            userChilds.push(menuItem);
          } else if (menuItem.roles.some(mRole => userRoles.includes(mRole))) {
            userChilds.push(menuItem);
          }
        }
      }
      const copyGroup = Object.assign({}, group);
      if (userChilds.length > 0) {
        copyGroup.childs = userChilds;
        userGroups.push(copyGroup);
      } else if (group.roles !== undefined && (group.roles.length === 0 || group.roles.some(mRole => userRoles.includes(mRole)))) {
        userGroups.push(group);
      }
    }
    return userGroups;
  }
}
