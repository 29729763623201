<div class="auth-container">
  <div class="line"></div>
  <div class="dots"></div>
  <div class="icon"></div>
  <div class="textBox">
    <h1>{{appTitle}}</h1>
  </div>
  <form>

    <div class="login-input">
      <i class="fas fa-user login-icon"></i>
      <input type="text" id="login" [(ngModel)]="authParam.login" name="login" placeholder="Имя пользователя"
        [disabled]="isAuthWait" autofocus required>
    </div>

    <div class="pwd-input">
      <i class="fas fa-unlock-alt pwd-icon"></i>
      <input [type]="pwdVisible ? 'text' : 'password'" id="pwd" [(ngModel)]="authParam.password" name="password"
        placeholder="Пароль" (keypress)="pwdKeyPress($event)" [disabled]="isAuthWait" required>
      <i class="visible-pwd-btn fas" [ngClass]="{'fa-eye': pwdVisible,'fa-eye-slash': !pwdVisible}"
        (click)="tooglePswd()"></i>
    </div>
  </form>

  <div class="btn-login-container">
    <input id="btLogin" (click)="loginClick()" type="button" [disabled]="isAuthWait"
      [value]=" isAuthWait ? 'Выполняется вход...' : 'Выполнить вход'">
    <i *ngIf="!isAuthWait" class="fas fa-long-arrow-alt-right btn-login-icon"></i>
    <i *ngIf="isAuthWait" class="pi pi-spin pi-spinner btn-login-icon"></i>
  </div>

  <div class="support" pTooltip="Номер телефона тех. поддержки или ссылка" tooltipPosition="bottom">
    <div class="support__logo">?</div>
    <div class="support__text">Служба тех. поддержки</div>
  </div>
</div>
