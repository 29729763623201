import { Injectable } from '@angular/core';

export enum MarkerType {
  All = -1,         // все объекты
  Progressing = 0,  // в работе
  Completed = 1,    // завершенные
  Warning = 2,      // имеются отклонения
  Critical = 3      // критические отклонения
}


@Injectable()
export class ObjectsMapService {
  constructor() { }

  private marker_gray = '../../../../assets/icons/marker-gray.png';
  private marker_blue = '../../../../assets/icons/marker-blue.png';
  private marker_yellow = '../../../../assets/icons/marker-yellow.png';
  private marker_green = '../../../../assets/icons/marker-green.png';
  private marker_red = '../../../../assets/icons/marker-red.png';

  /** возвращает url маркера на карте */
  public markerUrl(obj: any): string {
    if (!obj) {
      return '';
    }

    return this.marker_green;
    //const type = this.getTypeMarker(obj);
    //return this.markerUrlByType(type);
  }

  /** Возвращает url маркера на карте */
  public markerUrlByType(type: MarkerType): string {
    if (type === MarkerType.Completed) {
      return this.marker_green;
    } else if (type === MarkerType.Warning) {
      return this.marker_yellow;
    } else if (type === MarkerType.Critical) {
      return this.marker_red;
    } else if (type === MarkerType.All) {
      return this.marker_gray;
    } else {
      return this.marker_blue;
    }
  }

  /** Возвращает тип маркера для объекта */
  public getTypeMarker(): MarkerType {
    return MarkerType.Completed;
  }
}
