import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { locale } from 'moment';
import { PrimeNGConfig } from 'primeng/api';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AppEnviromentService } from './shared/services/app-enviroment.service';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public currentPageTitle: string = ''; // текущая активная страница
  constructor(private primengConfig: PrimeNGConfig,
    private authService: AuthService,
    public enviromentService: AppEnviromentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title) {
    locale(navigator.language);
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.translations();
    this.autoTitle();
  }

  isAutorized() {
    return this.authService.isLoggedIn;
  }

  /** Перевод текста */
  private translations(): void {
    this.primengConfig.setTranslation({
      monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      monthNamesShort: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Окт', 'Дек'],
      dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
      dayNamesShort: ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
      dayNamesMin: ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
      addRule: "Добавить условие",
      removeRule: 'Удалить условие',
      apply: "Применить",
      clear: "Очистить",
      matchAll: "И",
      matchAny: "ИЛИ",
      startsWith: "Начинается с",
      contains: "Содержит",
      endsWith: "Заканчивается на",
      equals: "Равно",
      notEquals: "Не равно",
      notContains: "Не содержит",
      dateIs: "Равно",
      dateIsNot: "Не равно",
      dateBefore: "Меньше",
      dateAfter: "Больше",
      //translations
    });
  }

  /** Автоматическая установка заголовков страниц */
  private autoTitle() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route: any) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route: any) => route.data)).subscribe((event: any) => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.enviromentService.shortTitle + ': ' + title);
          this.currentPageTitle = title;
        }
      });
  }
}
