import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppEnviromentService } from 'src/app/shared/services/app-enviroment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-dlg',
  templateUrl: './about-dlg.component.html',
  styleUrls: ['./about-dlg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutDlgComponent implements OnInit {
  public appName!: string;
  public clientVersion!: string;
  public serverVersion!: string;
  public dbName!: string;

  constructor(private enviromrntService: AppEnviromentService,
    private detector: ChangeDetectorRef,
    private ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.appName = this.enviromrntService.title;
    this.clientVersion = environment.VERSION;
    this.enviromrntService.getEnviromentInfo().subscribe(x => {
      this.serverVersion = x.version;
      this.dbName = x.connectionDb;
      this.detector.markForCheck();
    });
  }
}
