import { Component, ChangeDetectionStrategy } from '@angular/core';

/**Настройка столбцов таблицы */
@Component({
  selector: 'app-table-column-settings',
  templateUrl: './table-column-settings.component.html',
  styleUrls: ['./table-column-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableColumnSettingsComponent {

  constructor() { }

  /** Открыть окно настройки */
  public settingsClick() : void {

  }

}
