/** Роли пользователя */
export class UserRoles {
  public get(): string[] {
    const userRoleData = localStorage.getItem('user_roles');
    if (!userRoleData) {
      return [];
    }

    const userRoles = JSON.parse(userRoleData);
    return userRoles;
  }
}
