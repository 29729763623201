import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { TreeTable } from 'primeng/treetable';

/**Фильтр для таблицы */
@Component({
  selector: 'app-table-global-filter',
  templateUrl: './table-global-filter.component.html',
  styleUrls: ['./table-global-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableGlobalFilterComponent implements OnInit {
  @Input() table!: Table | TreeTable;
  constructor() { }

  ngOnInit(): void {
  }

}
