<div class="menu-wrapper">
  <div #menu class="menu">
    <div class="logo" [title]="appName">
      <img src="../../../../assets/icons/logo_with_name.png" alt="logo">
    </div>

    <div *ngFor="let menuItem of menuItems" class="menu-items-box" routerLinkActive="active" [attr.title]="menuItem.caption">
      <div class="menu-item-box" [routerLink]="menuItem.childs[0].path">
        <div class="menu-item-icon">
          <app-svg-icons class="menu-item-icon" [type]="menuItem.icon" color="#DCDCDC">
          </app-svg-icons>
        </div>
        <div [ngClass]="{'menu-item-text' : showMenuText, 'menu-item-text-less': !showMenuText}"
          (window:resize)="onResize($event)">
          <div *ngIf="showMenuText">
            {{menuItem.caption}}
          </div>
        </div>
      </div>

      <div class="submenu" routerLinkActive="expanded" *ngIf="menuItem.childs.length > 1">
        <div *ngFor="let child of menuItem.childs" [routerLink]="child.path" routerLinkActive="active"
          class="submenu-items-box" [attr.title]="child.caption">
          <div [ngClass]="{'submenu-item-box' : showMenuText, 'submenu-item-box-smol': !showMenuText}">
            <div class="submenu-text">{{child.caption}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="user-box">
      <div class="username-item-box">
        <div class="avatar-box">
          <app-svg-icons class="user-menu-item-icon" type="user" color="#DCDCDC"></app-svg-icons>
        </div>
        <div class="username-item">
          {{ userName }}
        </div>
      </div>
<!--
      <div class="user-item" [routerLink]="['/user-profile']" routerLinkActive="active">
        <div class="img-box" style="margin-top: 10px; margin-left: 13px;">
          <app-svg-icons class="app-icon" type="cog" color="#787888"></app-svg-icons>
        </div>
      </div>
-->
      <div class="user-item" (click)="logOut()">
        <div class="img-box">
          <app-svg-icons class="user-menu-item-icon" type="logout" color="#787888"></app-svg-icons>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content" [ngClass]="{'no-submenu': !hasSubmenu()}">
  <div class="content-wrapper-scroll">
    <div class=content-wrapper-margins>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
