import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginFormComponent } from './pages/login-form/login-form.component';
import { AuthGuardService } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginFormComponent,
    data: { title: 'Вход в систему' }
  },
  {
    path: 'dictionary',
    loadChildren: () => import('./modules/dictionary-module/dictionary.module').then(m => m.DictionaryModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'security',
    loadChildren: () => import('./modules/security-module/security.module').then(m => m.SecurityModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'routes',
    loadChildren: () => import('./modules/routes-module/routes.module').then(m => m.RoutesModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'report',
    loadChildren: () => import('./modules/report-module/report.module').then(m => m.ReportModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'report-ee',
    loadChildren: () => import('./modules/report-ee-module/report-ee.module').then(m => m.ReportEeModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'report-internal',
    loadChildren: () => import('./modules/report-internal-module/report-internal.module').then(m => m.InternalReportModule),
    canActivate: [AuthGuardService],
  },
  {
    path: '**',
    redirectTo: 'routes/journal'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
