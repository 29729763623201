<p-toolbar>
    <div class="p-toolbar-group-left">
    </div>
    <div class="p-toolbar-group-right" style="padding-top: 17px;">
        <div class="wrapper">
            <p-button type="button" label="{{cancelTitle}}" styleClass="app-btn-calcel-report" (onClick)="cancelClick()">
            </p-button>
            <p-button type="button" label="{{applyTitle}}" styleClass="app-btn-apply-report" active [disabled]="isDisabled" (onClick)="applyClick()">
            </p-button>
        </div>
    </div>
</p-toolbar>
