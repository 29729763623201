import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
import { EntityFileService } from '../../services/entity-file.service';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-photo-view',
  templateUrl: './photo-viewer.component.html',
  styleUrls: ['./photo-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PhotoViewerComponent implements OnInit {

  public isWait = true;
  public filePath: string;
  public env = environment;

  /** ctor */
  constructor(
    private service: EntityFileService,
    private fileService: FileService,
    private config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    if (this.config.data?.path) {
      this.filePath = this.config.data?.path;
    }
    this.isWait = false;
  }

  public download(): void {
    this.service.downloadFile(this.filePath).subscribe(response => {
      this.fileService.saveAs(response);
    });
    return;
  }

}
