<div class="app-btn-panel">
  <!-- <label class="btn-panel-lbl"><b>{{title}}</b></label>
  <span class="btn-separator"></span> -->
  <p-button *ngIf="isShowAddBtn" label="{{addLabel}}" title="Добавить новый элемент" icon="fas fa-plus-circle"
    styleClass="app-btn-gray" (onClick)="add.emit()"></p-button>
  <p-button *ngIf="isShowEditBtn" label="{{editLabel}}" title="Изменить элемент" icon="far fa-edit"
    styleClass="app-btn-gray" (onClick)="edit.emit()"></p-button>
  <p-button *ngIf="isShowDeleteBtn" label="{{deleteLabel}}" title="Удалить выбранный элемент" icon="far fa-trash-alt"
    styleClass="app-btn-gray" (onClick)="delete.emit()"></p-button>
  <p-button *ngIf="isShowExportBtn" label="{{exportLabel}}" title="Экспортировать данные в Excel" icon="far fa-file-excel"
    styleClass="app-btn-export" (onClick)="export.emit()"></p-button>

</div>