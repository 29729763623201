import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { PasswordModule } from 'primeng/password';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { AccordionModule } from 'primeng/accordion';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { RippleModule } from 'primeng/ripple';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { BadgeModule } from 'primeng/badge';
import { DataViewModule } from 'primeng/dataview';
import { FileUploadModule } from 'primeng/fileupload';
import {TreeTableModule} from 'primeng/treetable';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { ToolbarModule } from 'primeng/toolbar';
import { InputNumberModule } from 'primeng/inputnumber';
import {FieldsetModule} from 'primeng/fieldset';

const usedComponents = [
    CommonModule,
    TabMenuModule,
    InputTextModule,
    ButtonModule,
    ToggleButtonModule,
    PasswordModule,
    MessagesModule,
    ProgressSpinnerModule,
    DropdownModule,
    SplitButtonModule,
    ConfirmDialogModule,
    CardModule,
    CalendarModule,
    TableModule,
    TabViewModule,
    ToastModule,
    AccordionModule,
    DynamicDialogModule,
    MultiSelectModule,
    CheckboxModule,
    DialogModule,
    RippleModule,
    InputTextareaModule,
    MenubarModule,
    BadgeModule,
    DataViewModule,
    FileUploadModule,
    TreeTableModule,
    OverlayPanelModule,
    InputNumberModule,
    OverlayPanelModule,
    ToolbarModule,
    FieldsetModule
];

/** Содержит определения всех необходимых модулей Prime  для приложения */
@NgModule({
    declarations: [],
    imports: usedComponents,
    exports: usedComponents,
    providers: [],
})
export class PrimeContainerModule { }
