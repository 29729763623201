import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlertDlgComponent } from './components/alert-dlg/alert-dlg.component';
import { CancelSaveBtnComponent } from './components/cancel-save-btn/cancel-save-btn.component';
import { CancelSaveBtnReportComponent } from './components/cancel-save-btn-report/cancel-save-btn-report.component';
import { TableGlobalFilterComponent } from './components/table-global-filter/table-global-filter.component';
import { TableColumnSettingsComponent } from './components/table-column-settings/table-column-settings.component';
import { ValidateMsgDlgComponent } from './components/validate-msg-dlg/validate-msg-dlg.component';
import { PrimeContainerModule } from './prime-container.module';
import { EditPanelComponent } from './components/edit-panel/edit-panel.component';
import { PhotoViewerPopupComponent } from './components/photo-viewer-popup/photo-viewer-popup.component';
import { PhotoViewerComponent } from './components/photo-viewer/photo-viewer.component';
import { SvgIconsComponent } from './components/svg-icons/svg-icons.component';
import { ObjectsMapService } from './services/objects-map.service';

const components = [
    ValidateMsgDlgComponent,
    AlertDlgComponent,
    CancelSaveBtnComponent,
    CancelSaveBtnReportComponent,
    TableGlobalFilterComponent,
    TableColumnSettingsComponent,
    EditPanelComponent,
    PhotoViewerPopupComponent,
    PhotoViewerComponent,
    SvgIconsComponent
];

@NgModule({
    declarations: components,
    imports: [
        CommonModule,
        PrimeContainerModule,
    ],
    exports: components,
    providers: [ObjectsMapService],
})
export class SharedComponentsModule { }
