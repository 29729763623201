import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/** Производит изменение заголовков http запросов */
@Injectable()
export class TokenHttpInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newRequest = request;
    const token = localStorage.getItem('access_token');
    if (token) {
      newRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',    // чтобы отключить кеш IE
          Pragma: 'no-cache'
        }
      });
    }

    return next.handle(newRequest);
  }
}
