import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Используется для конвертации Date св-в объектов перед отправкой на сервер,
// чтобы избавить дату от признаков часового пояса машины, на которой исполняется клиент
@Injectable()
export class DateInterceptor implements HttpInterceptor {

  // Шаблон для проверки строки на дату в ИСО формате
  // tslint:disable:variable-name
  private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;
  // Шаблон для проверки строки на дату в формате "yyyy-MM-dd HH:mm"
  private _serverDateFormat = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}/;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newBody = req.body;
    this.convertToServer(newBody);

    const newReq = req.clone({ body: newBody });

    return next.handle(newReq).pipe(map((val: HttpEvent<any>) => {
      if (val instanceof HttpResponse) {
        const body = val.body;
        this.convertToClient(body);
      }
      return val;
    }));
  }

  private convertToClient(body: any): void {
    if (body === null || body === undefined
      || typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];

      if (value == null || value === undefined) {
        continue;
      }

      if (value instanceof Date) {
        console.log('Этой записи быть не должно, но если есть, значит с сервера дата-время прилетело не как строка');
      } else if (this.isServerDateString(value)) {
        body[key] = this.generateLocalDate(value);
      } else if (typeof value === 'object') {
        this.convertToClient(value);
      }
    }
  }

  private convertToServer(body: any): void {
    if (body === null || body === undefined
      || typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];

      if (value == null || value === undefined) {
        continue;
      }

      if (value instanceof Date) {
        body[key] = this.correctDate(value);
      } else if (this.isClientDateString(value)) {
        body[key] = this.correctDateString(value);
      } else if (typeof value === 'object') {
        this.convertToServer(value);
      }
    }
  }

  // Конвертирует локальное время в UTC и затем возвращает строку в формате "yyyy-MM-dd HH:mm"
  private correctUTCDate(value: Date): string {
    const years = value.getUTCFullYear();
    const months = this.addZero(value.getUTCMonth() + 1);
    const days = this.addZero(value.getUTCDate());
    const hours = this.addZero(value.getUTCHours());
    const minutes = this.addZero(value.getMinutes());

    return `${years}-${months}-${days} ${hours}:${minutes}`;
  }

  private correctDate(value: Date): string {
    const years = value.getFullYear();
    const months = this.addZero(value.getMonth() + 1);
    const days = this.addZero(value.getDate());
    const hours = this.addZero(value.getHours());
    const minutes = this.addZero(value.getMinutes());

    return `${years}-${months}-${days} ${hours}:${minutes}`;
  }

  // Конвертирует строку со временем в UTC в строку в формате "yyyy-MM-dd HH:mm"
  private correctDateString(value: string): string {
    return value.substring(0, 10) + ' ' + value.substring(11, 5);
  }

  private isClientDateString(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    if (typeof value === 'string') {
      return this._isoDateFormat.test(value);
    }

    return false;
  }

  private isServerDateString(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    if (typeof value === 'string') {
      return this._serverDateFormat.test(value);
    }

    return false;
  }

  private addZero(val: number): string {
    return val < 10 ? '0' + val : val.toString();
  }

  private generateLocalDate(val: string): Date {
    const dt = val.split(' ');
    const date = dt[0].split('-');
    const time = dt[1].split(':');

    // tslint:disable:radix
    const utcDt = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]),
      parseInt(time[0]), parseInt(time[1]));

    return utcDt;
  }

  private generateUTCLocalDate(val: string): Date {
    const dt = val.split(' ');
    const date = dt[0].split('-');
    const time = dt[1].split(':');

    const utcDt = Date.UTC(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]),
      parseInt(time[0]), parseInt(time[1]));

    return new Date(utcDt);
  }
}
