import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EntityFileTableDTO } from '../models/entity-file';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class EntityFileService {
  constructor(protected _http: HttpClient) { }
  protected _url = 'api/EntityFile/';

  // Получает фото по виду сущности и ИД сущности
  public getFilesByEntity(entityKind: number, entityId: number): Observable<EntityFileTableDTO[]> {
    return this._http.get<EntityFileTableDTO[]>(environment.API + this._url + 'getFilesByEntity/' + entityKind + '/' + entityId);
  }

  /** Скачать фото */
  public downloadFile(path: string): Observable<any> {
    return this._http.get(environment.API + this._url + 'download?path=' + path
      , { responseType: 'blob', observe: 'response' });
  }
}
