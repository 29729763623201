<div class="about-page">
    <div class="tape">
        <div>
            <h2>{{appName}}</h2>
            <br>
            <p>Версия клиентского приложения: {{clientVersion}}</p>
            <p>Версия серверного приложения: {{serverVersion}}</p>
            <p>База данных: {{dbName}}</p>
            <br>
            <p>ИНФОПРО © 2021. Все права защищены.</p>
        </div>
    </div>
</div>