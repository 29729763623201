import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

/** Описывает компонент для отображаения сообщения о проверках */
@Component({
  selector: 'app-validate-msg-dlg',
  templateUrl: './validate-msg-dlg.component.html',
  styleUrls: ['./validate-msg-dlg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidateMsgDlgComponent {
  public errorItems: string[] = [];

  /** ctor */
  constructor(private ref: DynamicDialogRef,
    private config: DynamicDialogConfig) {
    this.errorItems = this.config.data.textItems;
  }

  public okClick(): void {
    this.ref.close();
  }
}
