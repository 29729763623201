import { Component, OnInit, Input } from '@angular/core';
import { MenuGroupConfig } from 'src/app/menu-config';
import { AppEnviromentService } from 'src/app/shared/services/app-enviroment.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-main-form',
  templateUrl: './main-form.component.html',
  styleUrls: ['./main-form.component.scss']
})
export class MainFormComponent implements OnInit {
  @Input() currentPageTitle: string;  // текущая активная страница
  public menuItems: any; // Элементы меню
  public userName: string;  // имя пользователя
  public appName: string;
  public showMenuText: boolean; // скрыть/показать текст в меню

  /** ctor */
  constructor(private authService: AuthService,
    public enviromentService: AppEnviromentService,
    public confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.menuItems = new MenuGroupConfig().getUserMenuItems();
    this.authService.userName().subscribe(x => this.userName = x);
    this.appName = this.enviromentService.title;
    this.onResize();
  }

  /** Выход из аккаунта */
  public logOut(): void {
    this.confirmationService.confirm({
      message: 'Вы действительно хотите выйти из своего аккаунта?',
      acceptLabel: 'Подтвердить',
      rejectLabel: 'Отмена',
      acceptIcon: '',
      accept: () => {
        this.authService.logOutAndRoute();
      }
    });
  }

  /** отображается ли подменю? */
  public hasSubmenu(): boolean {
    return document.querySelector('.submenu.expanded') != null;
  }

  /** отображение текста в меню */
  public onResize(): void {
    this.showMenuText = window.innerWidth > 1320;
  }
}
