import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { GlobalMsgService } from '../services/global-msgs.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ValidateMsgDlgComponent } from '../components/validate-msg-dlg/validate-msg-dlg.component';

/** Перехватчик ошибок */
@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  /** ctor */
  constructor(private authService: AuthService,
    private dlgService: DialogService,
    private notify: GlobalMsgService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req)
      .pipe(catchError(err => {
        if (err instanceof HttpErrorResponse) {
          console.log(err);
          if (err.status === 400 || (err.status === 500 && err.error)) {
            this.notify.error(err.error.errors !== undefined ? err.error.errors[0].errorMessage : err.error);
            console.error(err.error);
          } else if (err.status === 0) {
            this.notify.error('СЕРВЕР НЕДОСТУПЕН!');
          } if (err.status === 403 && req.url !== environment.API + 'api/auth') {
            this.notify.error('Запрещен доступ к данному ресурсу/действию!');
            console.log('Запрещен доступ к данному ресурсу! Ресурс: ' + err.url);
          } if (err.status === 401) {
            console.log('error: Требуется пройти аутентификацию!');
            this.authService.logOutAndRoute();
          } if (err.status === 422) { // Ошибка проверки модели
            let msg: string = err.error;
            const errors = msg.split(/;/g,);
            this.dlgService.open(ValidateMsgDlgComponent, {
              header: 'Данные не прошли проверку',
              data: {
                textItems: errors,
              },
            });
          }
        }
        return throwError(err);
      }));
  }
}


