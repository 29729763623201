import { Injectable, Inject } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { AppEnviromentService } from './app-enviroment.service';

@Injectable({
  providedIn: 'root'
})

/** Сервис динамической загрузки библиотека яндекс-карт */
export class MapsLoaderService {
  private _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};

  constructor(@Inject(DOCUMENT) private readonly document: any, private appEnviromentService: AppEnviromentService) { }

  lazyLoadYmaps(): Observable<any> {
    return this.loadScript('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=' + this.appEnviromentService.getYaMapsKey());
  }

  private loadScript(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onload = () => {
      this._loadedLibraries[url].next();
      this._loadedLibraries[url].complete();
    };

    this.document.body.appendChild(script);

    return this._loadedLibraries[url].asObservable();
  }
}
