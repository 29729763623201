import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-edit-panel',
  templateUrl: './edit-panel.component.html',
  styleUrls: ['./edit-panel.component.scss']
})
export class EditPanelComponent implements OnInit {
  @Input() editEnable = true;   // кнопка редактирования
  @Input() addEnable = true;    // кнопка добавления
  @Input() deleteEnable = true; // кнопка удаления
  @Input() title: string | undefined;
  @Input() isShowEditBtn: boolean;
  @Input() isShowAddBtn: boolean;
  @Input() isShowDeleteBtn: boolean;
  @Input() isShowExportBtn: boolean;
  @Input() editLabel: string | undefined;
  @Input() addLabel: string | undefined;
  @Input() deleteLabel: string | undefined;
  @Input() exportLabel: string | undefined;

  @Output() add = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() export = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

}
