import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AlertDlgComponent } from '../components/alert-dlg/alert-dlg.component';

/** Сервси диалоговых сообщений */
@Injectable({ providedIn: 'root' })
export class AlertService {
    /** ctor */
    constructor(private dlgService: DialogService) {}

    /** Показывает сообщение с указанным заголовком */
    public show(title: string, message: string): void {
        this.dlgService.open(AlertDlgComponent, {
            header: title,
            closable: false,
            data: {
                message: message,
            },
        });
    }
}

